import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  LocalizationProvider,
  TimePicker,
  DatePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import axios from "axios";
import { toast } from "react-toastify";
import parsePhoneNumberFromString from "libphonenumber-js";

const validatePhoneNumber = (phoneNumber: string, countryCode: string) => {
  const phone = parsePhoneNumberFromString(countryCode + phoneNumber);
  return phone ? phone.isValid() : false;
};

const validationSchema = Yup.object().shape({
  workEmail: Yup.string().email("Invalid email").required("Required"),
  firstName: Yup.string()
    .min(3, "First name must be at least 3 characters")
    .required("Required"),
  countryCode: Yup.string()
    // .matches(/^\+\d{1,3}$/, "Invalid country code")
    .required("Required"),
  phoneNumber: Yup.string()
    .matches(/^\d+$/, "Phone number can only contain digits")
    .required("Required")
    .test(
      "valid-phone",
      "This phone number format is not recognized. Please check the country code and phone number",
      function (value) {
        const { countryCode } = this.parent;
        return validatePhoneNumber(value, countryCode);
      }
    ),
  date: Yup.date()
    .min(dayjs().startOf("day"), "Date cannot be in the past")
    .required("Required"),
  time: Yup.date()
    .required("Required")
    .test("is-future-time", "Time cannot be in the past", function (value) {
      const { date } = this.parent;
      if (!date || !value) return true;

      const selectedDate = dayjs(date).startOf("day");
      const selectedTime = dayjs(value);
      const combinedDateTime = selectedDate
        .hour(selectedTime.hour())
        .minute(selectedTime.minute())
        .second(selectedTime.second());

      return combinedDateTime.isAfter(dayjs());
    }),
});

const BookademoPopup = ({ demoOpen, setDemoOpen }: any) => {
  const [formState, setFormState] = useState<any>({
    workEmail: "",
    firstName: "",
    countryCode: "",
    phoneNumber: "",
    date: "",
    time: "",
  });
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [countryCodes, setCountryCodes] = useState<any>([]);

  useEffect(() => {
    const fetchCountryCodes = async () => {
      try {
        const response = await axios({
          url: `${process.env.REACT_APP_INBOX_API_URL}/CountryDetails/get-all-countries`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            // Authorization: localStorage.getItem("token"),
          },
        });
        setCountryCodes(response.data);
      } catch (error) {
        console.error("Error fetching country codes:", error);
        // toast.error('Failed to load country codes. Please try again.');
      }
    };

    fetchCountryCodes();
  }, []);

  // Function to validate phone number with country code

  const formik = useFormik({
    initialValues: {
      workEmail: "",
      firstName: "",
      countryCode: "+91",
      phoneNumber: "",
      date: null,
      time: null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const formattedDate = dayjs(values.date).format("YYYY-MM-DD");
      const formattedTime = dayjs(values.time).format("HH:mm:ss");
      const combinedDateTime = `${formattedDate}T${formattedTime}`;
      const fullPhoneNumber = values.countryCode + values.phoneNumber;
      const phoneNumber = parsePhoneNumberFromString(fullPhoneNumber);

      const newFormState = {
        ...values,
        scheduledTime: combinedDateTime,
        phoneNumber: phoneNumber
          ? phoneNumber.formatInternational()
          : fullPhoneNumber,
      };

      setFormState(newFormState);
      const apiEndpoint = process.env.REACT_APP_INBOX_API_URL;
      if (!apiEndpoint) {
        console.error("API endpoint is not defined");
        setSubmitting(false);
        return;
      }

      try {
        setIsLoading(true);
        const response = await axios.post(`${apiEndpoint}/book-demo`, {
          name: newFormState.firstName,
          email: newFormState.workEmail,
          scheduledTime: newFormState.scheduledTime,
        });
        const result: any = response.data.message;

        if (response.status >= 200 && response.status < 300) {
          setDemoOpen(false);
          setIsLoading(false);
          // dispatch(toastActions.setToaster({
          //     type: "success",
          //     message: result,
          // }))
          toast.success(result || "demo booked successfully.");
          formik.resetForm();
          handleClose();
        } else {
          console.error("Error:", result);
          // dispatch(toastActions.setToaster({
          //     type: "error",
          //     message: result || 'Failed to book demo. Please try again.',
          // }))
          toast.error(result || "Failed to book demo. Please try again.");
        }
      } catch (error) {
        console.error("Fetch Error:", error);
        // dispatch(toastActions.setToaster({
        //     type: "error",
        //     message: 'Failed to book demo. Please try again.',
        // }))
        toast.error("Failed to book demo. Please try again.");
      }

      setSubmitting(false);
      // handleClose();
    },
  });
  const [showErrors, setShowErrors] = useState(false);
  const handleSubmit = () => {
    setShowErrors(true);
    formik.handleSubmit();
  };

  const handleClose = () => {
    setDemoOpen(false);
    setShowErrors(false);
    formik.resetForm();
    setFormState({});
  };
  return (
    <Box>
      <Dialog
        open={demoOpen}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "20px",
          },
        }}
      >
        <DialogTitle>Book a demo</DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <Box
              sx={{
                "& .MuiTextField-root": { mb: 2, width: "100%" },
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  "& .MuiInputBase-root": {
                    height: "45px",
                    borderRadius: "12px",
                  },
                  "& .MuiInputBase-input": {
                    padding: "8px 14px",
                  },
                  "& .MuiInputLabel-root": {
                    top: "2.5px",
                  },
                  "& .MuiInputLabel-shrink": {
                    top: 0,
                  },
                }}
              >
                <TextField
                  name="workEmail"
                  label="Work Email"
                  value={formik.values.workEmail}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.workEmail && Boolean(formik.errors.workEmail)
                  }
                  helperText={
                    formik.touched.workEmail && formik.errors.workEmail
                  }
                  size="small"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  "& .MuiInputBase-root": {
                    height: "45px",
                    borderRadius: "12px",
                  },
                  "& .MuiInputBase-input": {
                    padding: "8px 14px",
                  },
                  "& .MuiInputLabel-root": {
                    top: "2.5px",
                  },
                  "& .MuiInputLabel-shrink": {
                    top: 0,
                  },
                }}
              >
                <TextField
                  name="firstName"
                  label="First Name"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                  size="small"
                />
              </Grid>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={6}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "45px",
                      borderRadius: "12px",
                    },
                    "& .MuiInputBase-input": {
                      padding: "8px 14px",
                    },
                    "& .MuiInputLabel-root": {
                      top: "2.5px",
                    },
                    "& .MuiInputLabel-shrink": {
                      top: 0,
                    },
                  }}
                >
                  <FormControl fullWidth size="small">
                    <InputLabel>Country Code</InputLabel>
                    <Select
                      name="countryCode"
                      label="Country Code"
                      value={formik.values.countryCode}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.countryCode &&
                        Boolean(formik.errors.countryCode)
                      }
                      displayEmpty
                      renderValue={(selected) => {
                        if (!selected) {
                          return <p style={{ color: "white" }}>Country Code</p>;
                        }
                        const selectedCountry =
                          countryCodes.find(
                            (country: any) => country.countryCode === selected
                          ) || "+91";
                        return (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Avatar
                              src={selectedCountry?.countryImage}
                              alt={selectedCountry?.countryName}
                              sx={{
                                width: 24,
                                height: 24,
                                marginRight: "10px",
                              }}
                            />
                            <Typography
                              variant="body2"
                              component="span"
                              color="textSecondary"
                              sx={{ marginRight: "10px" }}
                            >
                              ({selectedCountry?.countryCode})
                            </Typography>
                            <Tooltip
                              title={selectedCountry?.countryName}
                              placement="top"
                            >
                              <Typography
                                variant="body1"
                                component="span"
                                sx={{
                                  maxWidth: "150px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {selectedCountry?.countryName}
                              </Typography>
                            </Tooltip>
                          </div>
                        );
                      }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            maxHeight: 500,
                            "&::-webkit-scrollbar": {
                              width: "8px",
                            },
                            "&::-webkit-scrollbar-track": {
                              background: "#f1f1f1",
                            },
                            "&::-webkit-scrollbar-thumb": {
                              backgroundColor: "#888",
                              borderRadius: "10px",
                              border: "2px solid #f1f1f1",
                            },
                            "&::-webkit-scrollbar-thumb:hover": {
                              background: "#555",
                            },
                          },
                        },
                      }}
                    >
                      {countryCodes?.map((country: any) => (
                        <MenuItem key={country.id} value={country.countryCode}>
                          <Avatar
                            src={country.countryImage}
                            alt={country.countryName}
                            sx={{ width: 24, height: 24, marginRight: "10px" }}
                          />
                          <Typography
                            variant="body2"
                            component="span"
                            color="textSecondary"
                            sx={{ marginRight: "10px" }}
                          >
                            ({country.countryCode})
                          </Typography>
                          <Typography variant="body1" component="span">
                            {country.countryName}
                          </Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "45px",
                      borderRadius: "12px",
                    },
                    "& .MuiInputBase-input": {
                      padding: "8px 14px",
                    },
                    "& .MuiInputLabel-root": {
                      top: "2.5px",
                    },
                    "& .MuiInputLabel-shrink": {
                      top: 0,
                    },
                  }}
                >
                  <TextField
                    name="phoneNumber"
                    label="Phone Number"
                    type="number"
                    value={formik.values.phoneNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.phoneNumber &&
                      Boolean(formik.errors.phoneNumber)
                    }
                    helperText={
                      formik.touched.phoneNumber && formik.errors.phoneNumber
                    }
                    size="small"
                    sx={{
                      "& input[type=number]": {
                        "-moz-appearance": "textfield", 
                        appearance: "textfield", 
                      },
                      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                        {
                          WebkitAppearance: "none",
                          margin: 0,
                        },
                    }}
                  />
                </Grid>
              </Grid>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      "& .MuiInputBase-root": {
                        height: "45px",
                        borderRadius: "12px",
                      },
                      "& .MuiInputBase-input": {
                        padding: "8px 14px",
                      },
                      "& .MuiInputLabel-root": {
                        top: "-6px",
                      },
                      "& .MuiInputLabel-shrink": {
                        top: 0,
                      },
                    }}
                  >
                    <DatePicker
                      label="Date"
                      value={formik.values.date}
                      onChange={(value) => formik.setFieldValue("date", value)}
                      onClose={() => formik.setFieldTouched("date", true)}
                      minDate={dayjs()}
                      disablePast={true}
                      //@ts-ignore
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={
                            formik.touched.date && Boolean(formik.errors.date)
                          }
                          helperText={formik.touched.date && formik.errors.date}
                          onBlur={() => formik.setFieldTouched("date", true)}
                        />
                      )}
                      PopperProps={{
                        sx: {
                          "& .MuiCalendarPicker-root": {
                            maxHeight: "300px",
                          },
                          "& .MuiCalendarPicker-viewTransitionContainer": {
                            maxHeight: "240px",
                          },
                        },
                      }}
                    />
                    {showErrors &&
                      formik.touched.date &&
                      formik.errors.date && (
                        <div
                          style={{
                            color: "#d32f2f",
                            fontSize: "12.5px",
                            marginTop: "-10px",
                            marginLeft: "13px",
                          }}
                        >
                          {formik.errors.date}
                        </div>
                      )}
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      "& .MuiInputBase-root": {
                        height: "45px",
                        borderRadius: "12px",
                        overflow: "hidden",
                      },
                      "& .MuiInputLabel-root": {
                        top: "-6px",
                      },
                      "& .MuiInputLabel-shrink": {
                        top: 0,
                      },
                    }}
                  >
                    <TimePicker
                      label="Time"
                      value={formik.values.time}
                      onClose={() => formik.setFieldTouched("time", true)}
                      onChange={(value) => formik.setFieldValue("time", value)}
                      // minTime={
                      //     dayjs(formik.values.date).isSame(dayjs(), 'day')
                      //         ? dayjs().add(30, 'minute')
                      //         : undefined
                      // }
                      //@ts-ignore
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={
                            formik.touched.time && Boolean(formik.errors.time)
                          }
                          helperText={formik.touched.time && formik.errors.time}
                          size="small"
                        />
                      )}
                    />
                    {showErrors &&
                      formik.touched.time &&
                      formik.errors.time && (
                        <div
                          style={{
                            color: "#d32f2f",
                            fontSize: "12.5px",
                            marginTop: "-10px",
                            marginLeft: "13px",
                          }}
                        >
                          {formik.errors.time}
                        </div>
                      )}
                  </Grid>
                </Grid>
              </LocalizationProvider>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              color="secondary"
              sx={{
                border: "1.5px solid #00934F",
                textTransform: "capitalize",
                borderRadius: "50px",
                backgroundColor: "#FFFFFF",
                color: "#00934F",
                fontSize: "16px",
                width: "100px",
              }}
            >
              Cancel
            </Button>
            <Button
              // type="submit"
              onClick={handleSubmit}
              disabled={formik.isSubmitting}
              sx={{
                border: "1.5px solid #00934F",
                textTransform: "capitalize",
                borderRadius: "50px",
                backgroundColor: "#FFFFFF",
                color: "#00934F",
                fontSize: "16px",
                width: "130px",
              }}
            >
              {formik.isSubmitting ? "Booking..." : "Book a demo"}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      {isSuccess && (
        <Box
          sx={{
            mt: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" color="primary">
            Your demo booked successfully
          </Typography>
          {/* <Lottie options={defaultOptions} height={100} width={100} /> */}
        </Box>
      )}
    </Box>
  );
};

export default BookademoPopup;
