import React from "react";
import logo from "./logo.svg";
import "./App.css";
import WebsitePage from "./pages/WebsitePage/WebsitePage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import DetailsSection from "./pages/websitePaymentDetails/DetailsSection";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Box } from "@mui/material";
// import TemplateTesting from './pages/templateTesting/templateTesting';


function App() {
  return (
    <Box>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<WebsitePage />} />
          <Route path="/websitePaymentDetails" element={<DetailsSection />} />
          {/* <Route path='/templateTesting' element={<TemplateTesting />} /> */}
        </Routes>
        <ToastContainer />
      </BrowserRouter>
    </Box>
  );
}

export default App;
