import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTheme } from "@mui/material/styles";
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import MailIcon from '@mui/icons-material/Mail';
import VerifiedIcon from '@mui/icons-material/Verified';
import CampaignIcon from '@mui/icons-material/Campaign';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
// import { values } from 'lodash';
// import { useAppSelector } from '../../utils/redux-hooks';

const DetailsSection = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const [showSecondForm, setShowSecondForm] = useState(false);
    // const getAllCountryCodes = useAppSelector((state: any) => state.getAllCoutryCodes);
    // const getAllCountryCodesData = getAllCountryCodes?.data;
    // const codeArray =
    //     getAllCountryCodesData &&
    //     getAllCountryCodesData?.map((item: any) => ({
    //         countryCode: item?.countryCode,
    //         countryImage: item?.countryImage,
    //     }));
    const [countryCodes, setCountryCodes] = useState([]);
    // console.log(codeArray)

    // useEffect(() => {
    //     if (getAllCountryCodesData) {
    //         const codeArray = getAllCountryCodesData.map((item: any) => ({
    //             countryCode: item?.countryCode,
    //             countryImage: item?.countryImage,
    //         }));
    //         setCountryCodes(codeArray);
    //     }
    // }, [getAllCountryCodesData]);

    const formik = useFormik({
        initialValues: {
            workEmail: '',
            firstName: '',
            lastName: '',
            phoneNumber: '',
            companyName: '',
            companyWebsite: '',
            companyLocation: '',
            companyType: '',
            countryCode: ''
        },
        validationSchema: Yup.object({
            workEmail: Yup.string().email('Invalid email address').required('Work email is required'),
            firstName: Yup.string().required('First name is required'),
            lastName: Yup.string().required('Last name is required'),
            phoneNumber: Yup.string().required('Phone number is required'),
            companyName: Yup.string().required('Company name is required'),
            companyWebsite: Yup.string().required('Company website is required'),
            companyLocation: Yup.string().required('Company location is required'),
            companyType: Yup.string().required('Company type is required'),
            countryCode: Yup.string().required('Country code is required'),
        }),
        onSubmit: (values, { setSubmitting }) => {
            // Handle form submission here
            console.log(values);
            setSubmitting(false);
            // You can add your API call or other logic here
        },
    });
    const handleStartFreeTrial = (e: any) => {
        e.preventDefault();
        setShowSecondForm(true);
    };

    const Appinfo = [
        {
            icon: <MailIcon fontSize="large" />,
            title: 'Collaborate using Shared Team Inbox'
        },
        {
            icon: <CircleNotificationsIcon fontSize="large" />,
            title: 'Automate Store Notifications'
        },
        {
            icon: <VerifiedIcon fontSize="large" />,
            title: 'Green Tick Verification'
        },
        {
            icon: <CampaignIcon fontSize="large" />,
            title: 'Send bulk campaigns'
        },
        {
            icon: <TrendingUpIcon fontSize="large" />,
            title: 'Conversation Analytics'
        },
    ];



    return (
        <Box sx={{ backgroundColor: 'white' }}>
            <Grid container sx={{ display: 'flex', justifyContent: 'space-between', height: '100vh' }}>
                <Grid item xs={12} md={6}>
                    <Box sx={{ padding: 2, backgroundColor: '#cbf2e4', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Box>
                            <Box sx={{ textAlign: 'center', marginBottom: 2 }}>
                                <img
                                    src='https://www.interakt.shop/wp-content/uploads/2023/02/drive-marketing-768x327.png'
                                    alt="image"
                                    style={{ maxWidth: isMobile ? "100%" : "80%" }}
                                />
                            </Box>
                            <Grid container spacing={2}>
                                {Appinfo.map((item, index) => (
                                    <Grid item xs={12} sm={6} key={index}>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '10px',
                                            padding: 2,
                                            color: '#46c9a6',
                                            borderRadius: '8px',
                                        }}>
                                            {item.icon}
                                            <Typography variant='h6' style={{ fontSize: '16px', color: '#595958', fontWeight: 500 }}>
                                                {item.title}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                    {!showSecondForm ? (
                        <Box sx={{ padding: 2, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Box>
                                <form onSubmit={formik.handleSubmit}>
                                    <Box sx={{ mb: 2 }}>
                                        <TextField
                                            id="workEmail"
                                            name="workEmail"
                                            placeholder='Enter your work email'
                                            value={formik.values.workEmail}
                                            onChange={formik.handleChange}
                                            error={formik.touched.workEmail && Boolean(formik.errors.workEmail)}
                                            fullWidth
                                            sx={{
                                                backgroundColor: '#F4F6FC',
                                                borderRadius: '4px',
                                                height: '50px',
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        border: 'none',
                                                    },
                                                },
                                            }}
                                        />
                                        {formik.touched.workEmail && formik.errors.workEmail && (
                                            <p style={{ color: 'red', margin: '5px 0' }}>{formik.errors.workEmail}</p>
                                        )}
                                    </Box>
                                    <Box sx={{ display: 'flex', gap: '10px' }}>
                                        <Box>
                                            <TextField
                                                id="firstName"
                                                name="firstName"
                                                placeholder="Enter your first name"
                                                value={formik.values.firstName}
                                                onChange={formik.handleChange}
                                                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                                fullWidth
                                                sx={{
                                                    backgroundColor: '#F4F6FC',
                                                    borderRadius: '4px',
                                                    height: '50px',
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                            border: 'none',
                                                        },
                                                    },
                                                }}
                                            />
                                            {formik.touched.firstName && formik.errors.firstName && (
                                                <p style={{ color: 'red', margin: '5px 0' }}>{formik.errors.firstName}</p>
                                            )}
                                        </Box>
                                        <Box>
                                            <TextField
                                                id="lastName"
                                                name="lastName"
                                                placeholder="Enter your last name"
                                                value={formik.values.lastName}
                                                onChange={formik.handleChange}
                                                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                                fullWidth
                                                sx={{
                                                    backgroundColor: '#F4F6FC',
                                                    borderRadius: '4px',
                                                    height: '50px',
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                            border: 'none',
                                                        },
                                                    },
                                                }}
                                            />
                                            {formik.touched.lastName && formik.errors.lastName && (
                                                <p style={{ color: 'red', margin: '5px 0' }}>{formik.errors.lastName}</p>
                                            )}
                                        </Box>
                                    </Box>
                                    <Box sx={{ marginTop: '15px', display: 'flex', justifyContent: 'center' }}>
                                        <Button
                                            type="button"
                                            sx={{
                                                marginLeft: '15px',
                                                backgroundColor: '#00934F',
                                                color: '#FFFFFF',
                                                padding: '15px',
                                                textTransform: 'capitalize',
                                                fontSize: '16px',
                                                width: '100%',
                                                '&:hover': {
                                                    backgroundColor: '#00934F',
                                                },
                                            }}
                                            onClick={handleStartFreeTrial}
                                        >
                                            Start 14-day Free Trial
                                        </Button>
                                    </Box>
                                </form>
                            </Box>
                        </Box>
                    ) : (
                        <Box sx={{ height: '100vh', padding: 2, display: 'flex', alignItems: 'center' }}>
                            <Box>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Box
                                        sx={{
                                            backgroundColor: '#f2f2f2',
                                            display: 'flex',
                                            alignItems: 'center',
                                            padding: '14px',
                                            borderRadius: '8px',
                                            width: '50%',
                                        }}
                                    >
                                        <Box
                                            component="img"
                                            src="https://www.interakt.shop/wp-content/uploads/2023/01/blank_profile_img.webp"
                                            sx={{
                                                width: '50px',
                                                height: '50px',
                                                borderRadius: '50%',
                                                marginRight: '10px',
                                            }}
                                            alt="Profile Avatar"
                                        />
                                        <Box>
                                            <Typography variant="h6" sx={{ margin: 0, fontSize: '16px', fontWeight: 'bold' }}>Ram Prakash</Typography>
                                            <Typography variant="body1" sx={{ margin: 0, fontSize: '14px' }}>ramprakash@gmail.com</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box sx={{ padding: 2 }}>
                                    <form onSubmit={formik.handleSubmit}>
                                        <Grid container spacing={2}>
                                            {/* Country Code and Phone Number */}
                                            <Grid item xs={12} sm={6}>
                                                <FormControl fullWidth sx={{ backgroundColor: '#F4F6FC', borderRadius: '4px' }}>
                                                    {/* <InputLabel id="country-code-label">Country Code</InputLabel> */}
                                                    <Select
                                                        id="country-code"
                                                        name="countryCode"
                                                        value={formik.values.countryCode}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        displayEmpty
                                                        renderValue={(selected) => {
                                                            if (!selected) {
                                                                return <p style={{ color: '#bababa' }}>Country Code</p>;
                                                            }
                                                            const selectedCountry: any = countryCodes.find((country: any) => country.countryCode === selected);
                                                            return (
                                                                <>
                                                                    <img
                                                                        src={selectedCountry?.countryImage}
                                                                        alt=""
                                                                        style={{ width: '20px', marginRight: '8px' }}
                                                                    />
                                                                    {selectedCountry?.countryCode}
                                                                </>
                                                            );
                                                        }}
                                                        sx={{
                                                            height: '50px',
                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                border: 'none',
                                                            },
                                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                border: 'none',
                                                            },
                                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                border: 'none',
                                                            },
                                                        }}
                                                    >
                                                        {countryCodes.map((country: any) => (
                                                            <MenuItem key={country?.countryCode} value={country?.countryCode}>
                                                                <img
                                                                    src={country?.countryImage}
                                                                    alt=""
                                                                    style={{ width: '20px', marginRight: '8px' }}
                                                                />
                                                                {country?.countryCode}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                {formik.touched.countryCode && formik.errors.countryCode && (
                                                    <p style={{ color: 'red', margin: '5px 0' }}>{formik.errors.countryCode}</p>
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    id="phoneNumber"
                                                    name="phoneNumber"
                                                    placeholder="Enter your phone number"
                                                    value={formik.values.phoneNumber}
                                                    onChange={formik.handleChange}
                                                    error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                                                    fullWidth
                                                    sx={{
                                                        backgroundColor: '#F4F6FC',
                                                        borderRadius: '4px',
                                                        height: '50px',
                                                        '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                border: 'none',
                                                            },
                                                        },
                                                    }}
                                                />
                                                {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                                                    <p style={{ color: 'red', margin: '5px 0' }}>{formik.errors.phoneNumber}</p>
                                                )}
                                            </Grid>

                                            {/* Company Name */}
                                            <Grid item xs={6}>
                                                <TextField
                                                    id="companyName"
                                                    name="companyName"
                                                    placeholder="Enter your company name"
                                                    value={formik.values.companyName}
                                                    onChange={formik.handleChange}
                                                    error={formik.touched.companyName && Boolean(formik.errors.companyName)}
                                                    fullWidth
                                                    sx={{
                                                        backgroundColor: '#F4F6FC',
                                                        borderRadius: '4px',
                                                        height: '50px',
                                                        marginTop: 2,
                                                        '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                border: 'none',
                                                            },
                                                        },
                                                    }}
                                                />
                                                {formik.touched.companyName && formik.errors.companyName && (
                                                    <p style={{ color: 'red', margin: '5px 0' }}>{formik.errors.companyName}</p>
                                                )}
                                            </Grid>

                                            {/* Company Website */}
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    id="companyWebsite"
                                                    name="companyWebsite"
                                                    placeholder="Enter your company website"
                                                    value={formik.values.companyWebsite}
                                                    onChange={formik.handleChange}
                                                    error={formik.touched.companyWebsite && Boolean(formik.errors.companyWebsite)}
                                                    fullWidth
                                                    sx={{
                                                        backgroundColor: '#F4F6FC',
                                                        borderRadius: '4px',
                                                        height: '50px',
                                                        '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                border: 'none',
                                                            },
                                                        },
                                                    }}
                                                />
                                                {formik.touched.companyWebsite && formik.errors.companyWebsite && (
                                                    <p style={{ color: 'red', margin: '5px 0' }}>{formik.errors.companyWebsite}</p>
                                                )}
                                            </Grid>

                                            {/* Company Location */}
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    id="companyLocation"
                                                    name="companyLocation"
                                                    placeholder="Enter your company location"
                                                    value={formik.values.companyLocation}
                                                    onChange={formik.handleChange}
                                                    error={formik.touched.companyLocation && Boolean(formik.errors.companyLocation)}
                                                    fullWidth
                                                    sx={{
                                                        backgroundColor: '#F4F6FC',
                                                        borderRadius: '4px',
                                                        height: '50px',
                                                        '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                border: 'none',
                                                            },
                                                        },
                                                    }}
                                                />
                                                {formik.touched.companyLocation && formik.errors.companyLocation && (
                                                    <p style={{ color: 'red', margin: '5px 0' }}>{formik.errors.companyLocation}</p>
                                                )}
                                            </Grid>

                                            {/* Company Type */}
                                            <Grid item xs={6} sm={6}>
                                                <FormControl fullWidth sx={{ backgroundColor: '#F4F6FC', borderRadius: '4px' }}>
                                                    {/* <InputLabel>Company Type</InputLabel> */}
                                                    <Select
                                                        label="Company Type"
                                                        name="companyType"
                                                        value={formik.values.companyType}
                                                        onChange={formik.handleChange}
                                                        error={formik.touched.companyType && Boolean(formik.errors.companyType)}
                                                        displayEmpty
                                                        renderValue={(selected) => {
                                                            if (!selected) {
                                                                return <p style={{ color: '#bababa' }}>Company types</p>;
                                                            }
                                                            const selectedCountry: any = countryCodes.find((country: any) => country.code === selected);
                                                            return `${selectedCountry?.label} (${selectedCountry?.code})`;
                                                        }}
                                                        sx={{
                                                            height: '50px',
                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                border: 'none',
                                                            },
                                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                border: 'none',
                                                            },
                                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                border: 'none',
                                                            },
                                                        }}
                                                    >
                                                        <MenuItem value="E-commerce">E-commerce</MenuItem>
                                                        <MenuItem value="SaaS">SaaS</MenuItem>
                                                        <MenuItem value="Education">Education</MenuItem>
                                                    </Select>
                                                </FormControl>
                                                {formik.touched.companyType && formik.errors.companyType && (
                                                    <p style={{ color: 'red', margin: '5px 0' }}>{formik.errors.companyType}</p>
                                                )}
                                            </Grid>

                                            {/* Submit Button */}
                                            <Grid item xs={12}>
                                                <Box sx={{ marginTop: 2, display: 'flex', justifyContent: 'center' }}>
                                                    <Button
                                                        type="submit"
                                                        sx={{
                                                            backgroundColor: '#00934F',
                                                            color: '#FFFFFF',
                                                            padding: '15px',
                                                            textTransform: 'capitalize',
                                                            fontSize: '16px',
                                                            width: '100%',
                                                            '&:hover': {
                                                                backgroundColor: '#00934F',
                                                            },
                                                        }}
                                                    >
                                                        Submit
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Box>
                            </Box>
                        </Box>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

export default DetailsSection;
