import { Box, Button, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import BookademoPopup from "./BookademoPopup";
import { styled } from "@mui/system";
import { Element, Link } from "react-scroll";

const Footer = ({ demoOpen, setDemoOpen }: any) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  const handleDemoClick = () => {
    setDemoOpen(true);
  };
  const handleLoginClick = () => {
    navigate("/login");
  };
  const StyledIframe = styled("iframe")({
    width: "300px",
    height: "150px",
    borderRadius: "16px",
    marginBottom: "10px",
    border: "none",
  });

  return (
    <div>
      <Element name="aboutus">
        <Box
          sx={{
            backgroundColor: "#00934F",
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
            py: 3,
            px: 2,
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <Typography
              variant="h6"
              sx={{
                color: "#FFFFFF",
                mb: 1,
                fontSize: isMobile ? "22px" : "28px",
                fontWeight: "bold",
              }}
            >
              Try Engageto CRM free for first Quarter
            </Typography>
            <Typography
              sx={{
                color: "#FFFFFF",
                mb: 3,
                fontSize: isMobile ? "12px" : "15px",
              }}
            >
              Test drive Engageto CRM or book a product demo with the team.
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "12px",
                flexDirection: isMobile ? "column" : "row",
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  borderColor: "#FFFFFF",
                  textTransform: "capitalize",
                  borderRadius: "50px",
                  color: "#FFFFFF",
                  fontSize: "14px",
                  padding: "10px",
                  width: "150px",
                }}
                onClick={handleDemoClick}
              >
                Book a demo
              </Button>
              {/* <Button sx={{
                            backgroundColor: '#FFFFFF', padding: '10px', width: '150px', color: '#00934F', textTransform: 'capitalize', borderRadius: '50px', fontSize: '14px', '&:hover': {
                                backgroundColor: '#EDECF9',
                            },
                        }}
                            onClick={handleLoginClick}
                        >
                            Try it for free
                        </Button> */}
            </Box>
            {/* demoComponent */}
            <BookademoPopup demoOpen={demoOpen} setDemoOpen={setDemoOpen} />
            {/* footer2 */}
            <Element name="contactus">
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: "#1E1E1E",
                      borderRadius: "15px",
                      width: isMobile ? "100%" : "90%",
                      padding: isMobile ? "20px" : "50px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: isMobile ? "column" : "row",
                        alignItems: "flex-start",
                        gap: isMobile ? "20px" : "0",
                      }}
                    >
                      <Box
                        sx={{
                          width: isMobile ? "100%" : "50%",
                          display: "flex",
                          justifyContent: "flex-start",
                          flexDirection: "column",
                        }}
                      >
                        <Box
                          sx={{
                            backgroundColor: "white",
                            width: "fit-content",
                            borderRadius: "15px",
                          }}
                        >
                          <img
                            src="/engagetoLogo.png"
                            alt="logo"
                            width="130px"
                          />
                        </Box>
                        <Typography
                          variant="h6"
                          style={{
                            color: "#E1E1E1",
                            marginTop: "20px",
                            fontSize: "18px",
                            textAlign: "start",
                          }}
                        >
                          Our vision is to make Engageto an WhatsApp Business
                          platform for SMBs & D2C brands that provides efficient
                          & customized solutions.
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "20px",
                            marginTop: "40px",
                          }}
                        >
                          {/* <Box>
                                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop: '10px' }}>
                                                            <img src="/whatsupicon.png" width="30px" height="30px" />
                                                            <Typography style={{ color: '#E1E1E1', fontSize: '18px', textAlign: 'start' }}>7396986638</Typography>
                                                        </Box>
                                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop: '10px' }}>
                                                            <img src="/gmailicon.png" width="30px" height="22px" />
                                                            <Typography style={{ color: '#E1E1E1', fontSize: '18px', textAlign: 'start' }}>engagetocrm@gmail.com</Typography>
                                                        </Box>
                                                    </Box> */}
                          <img
                            src="/whatappqr.png"
                            width="120px"
                            height="120px"
                          />
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          width: isMobile ? "100%" : "50%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: isMobile ? "flex-start" : "flex-end",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: isMobile ? "column" : "row",
                            justifyContent: "flex-end",
                            width: "100%",
                            alignItems: isMobile ? "flex-start" : "center",
                            gap: "35px",
                            marginBottom: "20px",
                          }}
                        >
                          <Link to="home" smooth={true} duration={500}>
                            <Typography
                              sx={{
                                fontSize: "16px",
                                color: "white",
                                cursor: "pointer",
                              }}
                            >
                              Home
                            </Typography>
                          </Link>
                          <Link to="features" smooth={true} duration={500}>
                            <Typography
                              sx={{
                                fontSize: "16px",
                                color: "white",
                                cursor: "pointer",
                              }}
                            >
                              Features
                            </Typography>
                          </Link>
                          <Link to="pricing" smooth={true} duration={500}>
                            <Typography
                              sx={{
                                fontSize: "16px",
                                color: "white",
                                cursor: "pointer",
                              }}
                            >
                              Pricing
                            </Typography>
                          </Link>
                        </Box>
                        <Box
                          display="flex"
                          flexDirection="column"
                          width={{ xs: "100%", md: "90%" }}
                          fontFamily="Poppins, sans-serif"
                          mt={{ xs: 4, md: 0 }}
                          alignItems="flex-end"
                        >
                          <StyledIframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.5143932514766!2d78.36354037369051!3d17.43507700143248!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb900ed933ff81%3A0xb79715aed0874331!2sAhex%20Technologies%20Private%20Limited!5e0!3m2!1sen!2sin!4v1716819748783!5m2!1sen!2sin"
                            loading="lazy"
                          />
                          <Typography
                            variant="h6"
                            fontWeight="bold"
                            color="#FFFFFF"
                            textAlign="right"
                            mt={4}
                          >
                            India
                          </Typography>
                          <Typography
                            variant="body1"
                            color="#a6a5a4"
                            textAlign="right"
                          >
                            Unit 301 & 302, 3rd Floor, Magna Park View Towers,
                            near Urdu University Road, Sri Shyam Nagar, Telecom
                            Nagar Extension, Gachibowli, Hyderabad, Telangana
                            500032
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={{ marginTop: "20px", marginBottom: "10px" }}>
                      <hr style={{ border: "0.2px solid #E1E1E1" }} />
                    </Box>
                    <Typography
                      style={{
                        color: "#E1E1E1",
                        fontSize: "14px",
                        textAlign: isMobile ? "center" : "center",
                        marginTop: "auto",
                        marginBottom: "auto",
                      }}
                    >
                      © 2024 Ahex Technologies. All rights reserved.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Element>
          </Box>
        </Box>
      </Element>
    </div>
  );
};

export default Footer;
