/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  CardContent,
  useMediaQuery,
  CssBaseline,
  AppBar,
  Tabs,
  Tab,
  Toolbar,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import { Element } from "react-scroll";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CampaignIcon from "@mui/icons-material/Campaign";
import ViewComfyIcon from "@mui/icons-material/ViewComfy";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import FlagIcon from "@mui/icons-material/Flag";

const WebsitePage = () => {
  // const theme = useTheme();

  const theme = createTheme({
    components: {
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: "none",
            fontWeight: "bold",
            fontSize: "16px",
            overflow: "auto",
            borderRadius: "8px",
            "&.Mui-selected": {
              color: "white",
              backgroundColor: "#00934F",
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          indicator: {
            display: "none",
          },
        },
      },
    },
  });
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [planType, setPlanType] = useState("quarterly");
  const navigate = useNavigate();
  const [demoOpen, setDemoOpen] = useState(false);
  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleBookDemo = () => {
    setDemoOpen(true);
  };

  const handleStartFreeTrail = () => {
    navigate("/websitePaymentDetails");
  };
  const Cdata = [
    {
      title: "Automate Support",
      description:
        "Introducing chatbots to your live conversations will save a lot of effort.",
      icon: "SupportAgentIcon",
    },
    {
      title: "Create Template",
      description:
        "Utilize pre-made templates to reduce message creation time and increase efficiency.",
      icon: "ViewComfyIcon",
    },
    {
      title: "Messaging Campaigns",
      description:
        "Enable real-time communication through Whatsup to promptly address customer inquiries and feedback, bolstering engagement",
      icon: "CampaignIcon",
    },
  ];

  const data = [
    {
      title: "Messaging Campaigns",
      description:
        "Campaigns utilize WhatsApp for precise targeting of customer segments based on demographics, behaviors, and interests. Campaigns help implement interactive elements in campaigns to drive engagement and increase reach among the target audience.",
      image: "/messagecampain.png",
    },
    {
      title: "Analytics",
      description:
        "Analytics leverage meta analytics to monitor and analyze performance metrics. We can track and assess KPIs to measure the effectiveness of customer engagement strategies.",
      image: "/analytics.png",
    },
    {
      title: "Contacts",
      description:
        "Contacts integrates Customer Relationship Management systems for efficient contact management. We automate the contact management processes to streamline operations and enhance customer interactions.",
      image: "/contacts.png",
    },
    {
      title: "Schedule Campaigns",
      description:
        "A scheduled campaign is designed to automatically send a companys messaging to consumers along a set timetable. Scheduling campaigns helps make a list of all planned marketing activities, including ongoing and one-time campaigns, events, content releases, and promotions.",
      image: "/schedulecampain.png",
    },
    {
      title: "AI Integrations",
      description:
        "AI Integrations helps understanding your specific business needs and context. They align with your goals and processes, and help data Integration. Our integration analyzes customer interactions (such as emails, chats, or social media) to gauge sentiment.",
      image: "/aiaissistant.png",
    },
    {
      title: "Roles Management",
      description:
        "Roles Management helps in efficient lead management in real-time scenarios. It also helps in personalization of the application as per your requirements and decreases manual tasks to increase efficiency and decrease time.",
      image: "/rolesmanagment.png",
    },
  ];
  const pricingData: any = {
    quarterly: [
      {
        title: "Basic",
        price: "4125",
        features: [
          "Unlimited agents",
          "1000 free service conversations",
          "₹ 0.95/Marketing Conversation",
          // 'All others category',
          "₹ 0.35/Utility & Authentication Conversation",
          // "No Automations",
          // "No Analytics(coming soon)"
        ],
        button: "Choose Plan",
      },
      {
        title: "Pro",
        price: "5500",
        features: [
          "Entails all basic plan features",
          "₹ 0.90/Marketing Conversation",
          // 'All others category',
          "₹ 0.35 Utility & Authentication Conversation",
          "AI Integrations",
          // "No Automations",
          // "No Analytics(coming soon)"
        ],
        button: "Choose Plan",
      },
      {
        title: "Enterprise",
        price: "Custom",
        features: [
          // 'Unlimited agents',
          // '1000 free service conversations',
          // '₹ 0.90/Marketing Conversation',
          // 'All others category',
          // "₹ 0.45/Conversation",
          // "No Automations",
          // "No Analytics(coming soon)"
          "Entails all pro plan features",
          "Customized development",
          "AI Integrations",
        ],
        button: "Choose Plan",
      },
    ],
    annually: [
      {
        title: "Basic",
        price: "15,000",
        features: [
          "Unlimited agents",
          "1000 free service conversations",
          "₹ 0.95/Marketing Conversation",
          // 'All others category',
          "₹ 0.35/Utility & Authentication Conversation",
          // "No Automations",
          // "No Analytics(coming soon)"
        ],
        button: "Choose Plan",
      },
      {
        title: "Pro",
        price: "20,000",
        features: [
          "Entails all basic plan features",
          "₹ 0.90/Marketing Conversation",
          // 'All others category',
          "₹ 0.35/Utility & Authentication Conversation",
          "AI Integrations",
          // "No Automations",
          // "No Analytics(coming soon)"
        ],
        button: "Choose Plan",
      },
      {
        title: "Enterprise",
        price: "Custom",
        features: [
          // 'Unlimited agents',
          // '1000 free service conversations',
          // '₹ 0.90/Marketing Conversation',
          // 'All others category',
          // "₹ 0.45/Conversation",
          // "No Automations",
          // "No Analytics(coming soon)"
          "Entails all pro features",
          "Customized development",
          "AI Integrations",
        ],
        button: "Choose Plan",
      },
    ],
  };
  return (
    <div
      style={{
        // backgroundImage: `url("/admin-login-bg.png")`,
        // backgroundSize: "cover",
        // backgroundPosition: "center",
        // backgroundAttachment: "fixed",
        position: "relative",
        // minHeight: '100vh',
      }}
    >
      <Navbar demoOpen={demoOpen} setDemoOpen={setDemoOpen} />
      <Element name="home">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mb: 4,
            mt: 5,
            // backgroundImage: `url("/admin-login-bg.png")`,
            // // backgroundSize: "cover",
            // backgroundPosition: "center",
            // backgroundAttachment: "fixed",
            backgroundImage: `url("/admin-login-bg.png")`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            px: 8,
          }}
        >
          <Box
            display="flex"
            flexDirection={{ xs: "column", md: "row" }}
            justifyContent="center"
            alignItems="center"
            p={5}
          >
            <Box
              flex={1}
              textAlign={{ xs: "center", md: "left" }}
              mb={{ xs: 4, md: 0 }}
            >
              <Box sx={{ display: "flex", width: "100%", pb: 2 }}>
                <Box
                  sx={{
                    backgroundColor: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "400px",
                    height: "30px",
                    borderRadius: "20px",
                    // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                    border: "1px solid green",
                  }}
                >
                  <WhatsAppIcon sx={{ mr: 1, fontSize: 20, color: "green" }} />
                  <Typography
                    variant="body2"
                    fontWeight="medium"
                    style={{ color: "green", fontSize: "16px" }}
                  >
                    Powered by Official WhatsApp Business API
                  </Typography>
                </Box>
              </Box>
              <Typography
                variant="h4"
                style={{ fontSize: "18px", fontWeight: 500 }}
              >
                The #1 WhatsApp CRM
              </Typography>
              <Typography
                variant="h2"
                style={{
                  fontWeight: "bold",
                  fontSize: "36px",
                  lineHeight: "1.2",
                }}
              >
                Craft your conversations effectively.
              </Typography>
              <Button
                style={{
                  color: "white",
                  backgroundColor: "#00934F",
                  fontSize: "16px",
                  textTransform: "capitalize",
                  marginTop: "20px",
                  borderRadius: "50px",
                  width: "145px",
                }}
                onClick={handleBookDemo}
              >
                Book a demo
              </Button>
            </Box>

            <Box
              flex={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <img
                src="/virtual-assistant.png"
                alt="virtual-assistant"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </Box>
          </Box>
        </Box>
      </Element>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: {
              xs: "100%",
              md: "85%",
            },
            padding: {
              xs: "20px",
              md: "0px",
            },
          }}
        >
          {/* image and text section */}
          <Element name="features">
            {/* key feature text */}
            {/* <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
                            <Box>
                                <Typography variant='h2' style={{ fontWeight: 'bold', fontSize: '36px', lineHeight: '1.2', textAlign: 'center' }}>Key features</Typography>
                                <p style={{ fontSize: '18px', textAlign: 'center' }}>Engageto CRM equipped with dozens of features to empower your <br /> team to sell more effectively.</p>
                            </Box>
                        </Box> */}
            <Box sx={{ width: "100%" }}>
              {/* Top Navigation Bar */}
              <AppBar
                position="static"
                color="inherit"
                sx={{ border: "none", boxShadow: "none", px: 2 }}
              >
                <Box sx={{ display: "flex", justifyContent: "center", pb: 2 }}>
                  <Box>
                    {/* <Typography
                      variant="h2"
                      style={{ fontSize: "16px", textAlign: "center" }}
                      mt={2}
                    >
                      Key features
                    </Typography> */}
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: "36px",
                        textAlign: "center",
                      }}
                    >
                      Reach and notify customers on WhatsApp at scale
                    </Typography>
                  </Box>
                </Box>
                {/* card1 */}
                <Box
                  sx={{
                    backgroundColor: "#F8F8F8",
                    height: "auto",
                    borderRadius: "15px",
                    display: "flex",
                    justifyContent: "center",
                    mb: 4,
                  }}
                >
                  <Box sx={{ height: "auto", width: "100%" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src="/ClientChat.png"
                        alt="image"
                        style={{
                          maxWidth: isMobile ? "100%" : "90%",
                          height: "auto",
                          display: "block",
                          objectFit: "cover",
                          border: "3px solid #EEEEEE",
                          borderRadius: "15px",
                        }}
                      />
                    </Box>
                  </Box>
                </Box>

                {/* Responsive Grid with 3 Cards */}
                <Box sx={{ mb: 4 }}>
                  <Grid container spacing={3}>
                    {Cdata.map((item, index) => (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <Box
                          sx={{
                            height: "100%",
                            backgroundColor: "#F8F8F8",
                            borderRadius: "15px",
                            display: "flex",
                            flexDirection: "column",
                            overflow: "hidden",
                          }}
                        >
                          <Box
                            style={{ paddingTop: "15px", paddingLeft: "15px" }}
                          >
                            {item.icon === "CampaignIcon" ? (
                              <CampaignIcon sx={{ color: "green" }} />
                            ) : item.icon === "ViewComfyIcon" ? (
                              <ViewComfyIcon sx={{ color: "green" }} />
                            ) : (
                              <SupportAgentIcon sx={{ color: "green" }} />
                            )}
                          </Box>
                          <CardContent
                            sx={{
                              flex: 1,
                              display: "flex",
                              flexDirection: "column",
                              paddingTop: "0",
                            }}
                          >
                            <Typography
                              variant="h6"
                              component="div"
                              sx={{
                                fontWeight: "bold",
                                fontSize: "27px",
                                marginTop: "5px",
                              }}
                            >
                              {item.title}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              sx={{
                                mt: 1,
                                fontSize: "16px",
                                color: "#848199",
                                flexGrow: 1,
                              }}
                            >
                              {item.description}
                            </Typography>
                          </CardContent>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </AppBar>
            </Box>
            <Box sx={{ display: "flex" }}>
              {/* switch Content Area */}
              <Box sx={{ width: "100%", pb: 2 }}>
                {/* Top Navigation Bar */}
                <ThemeProvider theme={theme}>
                  <Box sx={{ display: "flex" }}>
                    {/* Main Content Area */}
                    <Box sx={{ flexGrow: 1 }}>
                      {/* Top Navigation Bar */}
                      <AppBar
                        position="static"
                        color="inherit"
                        sx={{ border: "none", boxShadow: "none" }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            mb: 1,
                          }}
                        >
                          <Box>
                            <Typography
                              variant="h2"
                              style={{
                                fontSize: "28px",
                                fontWeight: "bold",
                                textAlign: "center",
                              }}
                              mt={2}
                            >
                              Key features
                            </Typography>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                fontSize: "36px",
                                textAlign: "center",
                              }}
                            >
                              Reach and notify customers on WhatsApp at scale
                            </Typography>
                          </Box>
                        </Box>
                        <Tabs
                          value={tabValue}
                          onChange={handleTabChange}
                          centered
                        >
                          <Tab label="Analytics" />
                          <Tab label="Contacts" />
                          <Tab label="Schedule Campaigns" />
                          <Tab label="Wallet" />
                          <Tab label="Roles Management" />
                          <Tab label="AI Integrations" />
                        </Tabs>
                        {/* Main Content */}
                        <Box>
                          {tabValue === 0 && (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                mb: 4,
                              }}
                            >
                              <Box sx={{ height: "auto", width: "100%" }}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: { xs: "column", md: "row" },
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "60px",
                                    padding: { xs: "20px", md: "30px" },
                                  }}
                                >
                                  <img
                                    src="/analytics.png"
                                    alt="image"
                                    style={{
                                      maxWidth: isMobile ? "60%" : "50%",
                                      height: "auto",
                                      display: "block",
                                      objectFit: "cover",
                                      border: "3px solid #EEEEEE",
                                      borderRadius: "15px",
                                    }}
                                  />
                                  <Box>
                                    <Typography
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "30px",
                                      }}
                                    >
                                      Analytics
                                    </Typography>
                                    <p
                                      style={{
                                        fontSize: "16px",
                                        color: "#848199",
                                      }}
                                    >
                                      Analytics leverage meta analytics to
                                      monitor and analyze performance metrics.
                                      We can track and assess KPIs to measure
                                      the effectiveness of customer engagement
                                      strategies.
                                    </p>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          )}
                          {tabValue === 1 && (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                mb: 4,
                              }}
                            >
                              <Box sx={{ height: "auto", width: "100%" }}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: { xs: "column", md: "row" },
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "60px",
                                    padding: { xs: "20px", md: "30px" },
                                  }}
                                >
                                  <img
                                    src="/contacts.png"
                                    alt="image"
                                    style={{
                                      maxWidth: isMobile ? "60%" : "50%",
                                      height: "auto",
                                      display: "block",
                                      objectFit: "cover",
                                      border: "3px solid #EEEEEE",
                                      borderRadius: "15px",
                                    }}
                                  />
                                  <Box>
                                    <Typography
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "30px",
                                      }}
                                    >
                                      Contacts
                                    </Typography>
                                    <p
                                      style={{
                                        fontSize: "16px",
                                        color: "#848199",
                                      }}
                                    >
                                      Contacts integrates Customer Relationship
                                      Management systems for efficient contact
                                      management. We automate the contact
                                      management processes to streamline
                                      operations and enhance customer
                                      interactions.
                                    </p>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          )}
                          {tabValue === 2 && (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                mb: 4,
                              }}
                            >
                              <Box sx={{ height: "auto", width: "100%" }}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: { xs: "column", md: "row" },
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "60px",
                                    padding: { xs: "20px", md: "30px" },
                                  }}
                                >
                                  <img
                                    src="/schedulecampain.png"
                                    alt="image"
                                    style={{
                                      maxWidth: isMobile ? "60%" : "50%",
                                      height: "auto",
                                      display: "block",
                                      objectFit: "cover",
                                      border: "3px solid #EEEEEE",
                                      borderRadius: "15px",
                                    }}
                                  />
                                  <Box>
                                    <Typography
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "30px",
                                      }}
                                    >
                                      Schedule Campaigns
                                    </Typography>
                                    <p
                                      style={{
                                        fontSize: "16px",
                                        color: "#848199",
                                      }}
                                    >
                                      A scheduled campaign is designed to
                                      automatically send a companys messaging to
                                      consumers along a set timetable.
                                      Scheduling campaigns helps make a list of
                                      all planned marketing activities,
                                      including ongoing and one-time campaigns,
                                      events, content releases, and promotions.
                                    </p>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          )}
                          {tabValue === 3 && (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                mb: 4,
                              }}
                            >
                              <Box sx={{ height: "auto", width: "100%" }}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: { xs: "column", md: "row" },
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "60px",
                                    padding: { xs: "20px", md: "30px" },
                                  }}
                                >
                                  <img
                                    src="/wallet.png"
                                    alt="image"
                                    style={{
                                      maxWidth: isMobile ? "60%" : "50%",
                                      height: "auto",
                                      display: "block",
                                      objectFit: "cover",
                                      border: "3px solid #EEEEEE",
                                      borderRadius: "15px",
                                    }}
                                  />
                                  <Box>
                                    <Typography
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "30px",
                                      }}
                                    >
                                      Wallet
                                    </Typography>
                                    <p
                                      style={{
                                        fontSize: "16px",
                                        color: "#848199",
                                      }}
                                    >
                                      The wallet has a healthy available balance
                                      and is currently in good standing. There
                                      have been no recent transactions. The user
                                      has the ability to add more funds and view
                                      their transaction history. Additionally,
                                      there is a subscription deduction for
                                      "Subscription Charges-renewal" with an
                                      unlimited status. Overall, the wallet is
                                      sufficiently funded for upcoming services.
                                    </p>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          )}
                          {tabValue === 4 && (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                mb: 4,
                              }}
                            >
                              <Box sx={{ height: "auto", width: "100%" }}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: { xs: "column", md: "row" },
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "60px",
                                    padding: { xs: "20px", md: "30px" },
                                  }}
                                >
                                  <img
                                    src="/rolesmanagment.png"
                                    alt="image"
                                    style={{
                                      maxWidth: isMobile ? "60%" : "50%",
                                      height: "auto",
                                      display: "block",
                                      objectFit: "cover",
                                      border: "3px solid #EEEEEE",
                                      borderRadius: "15px",
                                    }}
                                  />
                                  <Box>
                                    <Typography
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "30px",
                                      }}
                                    >
                                      Roles Management
                                    </Typography>
                                    <p
                                      style={{
                                        fontSize: "16px",
                                        color: "#848199",
                                      }}
                                    >
                                      Roles Management helps in efficient lead
                                      management in real-time scenarios. It also
                                      helps in personalization of the
                                      application as per your requirements and
                                      decreases manual tasks to increase
                                      efficiency and decrease time.
                                    </p>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          )}
                          {tabValue === 5 && (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                mb: 4,
                              }}
                            >
                              <Box sx={{ height: "auto", width: "100%" }}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: { xs: "column", md: "row" },
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "60px",
                                    padding: { xs: "20px", md: "30px" },
                                  }}
                                >
                                  <img
                                    src="/aiaissistant.png"
                                    alt="image"
                                    style={{
                                      maxWidth: isMobile ? "60%" : "50%",
                                      height: "auto",
                                      display: "block",
                                      objectFit: "cover",
                                      border: "3px solid #EEEEEE",
                                      borderRadius: "15px",
                                    }}
                                  />
                                  <Box>
                                    <Typography
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "30px",
                                      }}
                                    >
                                      AI Integrations
                                    </Typography>
                                    <p
                                      style={{
                                        fontSize: "16px",
                                        color: "#848199",
                                      }}
                                    >
                                      Also, Connect your CRM, Payment Portals,
                                      eCommerce Platforms like Shopify,
                                      WooCommerce, Hubspot. Zapier via APIs.
                                    </p>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          )}
                        </Box>
                      </AppBar>
                    </Box>
                  </Box>
                </ThemeProvider>
              </Box>
            </Box>
          </Element>
        </Box>
      </Box>
      <Box
        style={{
          backgroundImage: `url("/admin-login-bg.png")`,
          backgroundSize: "cover",
          backgroundPosition: "10% 10%",
          position: "relative",
        }}
        sx={{ pb: 2 }}
      >
        {/* pricing section */}
        <Element name="pricing">
          <Box sx={{ display: "flex", justifyContent: "center", mb: 2, pt: 2 }}>
            <Box>
              <Typography
                variant="h2"
                style={{
                  fontWeight: "bold",
                  fontSize: "30px",
                  lineHeight: "1.2",
                  textAlign: "center",
                }}
              >
                Simple, Transparent Pricing
              </Typography>
              <p style={{ fontSize: "16px", textAlign: "center" }}>
                No contracts. No surprise fees.
              </p>
            </Box>
          </Box>
        </Element>

        {/* Buttons for plan selection */}
        <Box textAlign="center">
          <Box
            sx={{
              display: "inline-flex",
              backgroundColor: "white",
              borderRadius: "25px",
              position: "relative",
              overflow: "hidden",
              padding: 0,
              border: "1px solid #e0e0e0",
            }}
          >
            <Button
              sx={{
                backgroundColor: "transparent",
                color: planType === "quarterly" ? "white" : "black",
                borderRadius: "25px",
                width: "100px",
                height: "45px",
                cursor: "pointer",
                fontSize: "12px",
                fontWeight: "bold",
                zIndex: 1,
                transition: "color 0.3s",
                "&:hover": {
                  backgroundColor: "transparent",
                },
                padding: 0,
              }}
              onClick={() => setPlanType("quarterly")}
            >
              Quarterly
            </Button>
            <Button
              sx={{
                backgroundColor: "transparent",
                color: planType === "annually" ? "white" : "black",
                borderRadius: "25px",
                width: "100px",
                height: "45px",
                cursor: "pointer",
                fontSize: "12px",
                fontWeight: "bold",
                zIndex: 1,
                transition: "color 0.3s",
                "&:hover": {
                  backgroundColor: "transparent",
                },
                padding: 0,
              }}
              onClick={() => setPlanType("annually")}
            >
              Annually
            </Button>
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: planType === "quarterly" ? 0 : "50%",
                width: "50%",
                height: "100%",
                backgroundColor: "#00934F",
                borderRadius: "25px",
                transition: "left 0.3s",
                zIndex: 0,
              }}
            />
          </Box>
        </Box>
        {/* pricing cards */}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "center",
            alignItems: "stretch",
            gap: "20px",
          }}
        >
          {pricingData[planType]?.map((plan: any, index: any) => (
            <Box
              key={index}
              mt={4}
              sx={{
                width: { xs: "60%", sm: "80%", md: "300px" },
                maxWidth: "400px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: "15px",
                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.0)",
                  transition:
                    "box-shadow 0.2s ease-in-out, background-color 0.2s ease-in-out, color 0.2s ease-in-out",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "100%",
                  minHeight: "400px",
                  "&:hover": {
                    boxShadow: "0px 8px 20px rgba(0, 197, 89, 1.5)",
                    backgroundColor: "#00934F",
                    color: "#FFFFFF",
                    ".hover-text": {
                      color: "#FFFFFF",
                    },
                    ".hover-icon": {
                      color: "#FFFFFF",
                    },
                  },
                }}
              >
                <Box
                  p={3}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  {plan?.price && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "baseline",
                        my: 2,
                      }}
                    >
                      <Typography
                        variant="h3"
                        sx={{
                          fontWeight: "bold",
                          fontSize: { xs: "20px", sm: "24px" },
                          display: "flex",
                          alignItems: "center",
                        }}
                        className="hover-text"
                      >
                        <CurrencyRupeeIcon
                          sx={{
                            fontSize: { xs: "16px", sm: "20px" },
                            color: "gray",
                            transition: "color 0.2s ease-in-out",
                          }}
                          className="hover-icon"
                        />
                        {plan.price}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          ml: 1,
                          fontSize: { xs: "12px", sm: "14px" },
                          color: "gray",
                          transition: "color 0.2s ease-in-out",
                        }}
                        className="hover-text"
                      >
                        /{planType === "quarterly" ? "quarterly" : "annually"}
                      </Typography>
                    </Box>
                  )}
                  <Typography
                    variant="h5"
                    sx={{
                      mb: 1,
                      fontWeight: "bold",
                      fontSize: { xs: "24px", sm: "28px" },
                      color: "#000",
                      transition: "color 0.2s ease-in-out",
                    }}
                    className="hover-text"
                  >
                    {plan.title}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      mb: 2,
                      fontSize: { xs: "12px", sm: "14px" },
                      color: "#000",
                      transition: "color 0.2s ease-in-out",
                    }}
                    className="hover-text"
                  >
                    For most businesses that want to optimize web queries
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      flexGrow: 1,
                    }}
                  >
                    {plan.features.map((feature:any, featureIndex:any) => (
                      <Box
                        key={featureIndex}
                        sx={{
                          display: "flex",
                          alignItems: "flex-start", 
                          gap: "10px",
                          mb: 1,
                        }}
                      >
                        <CheckCircleOutlineIcon
                          sx={{
                            color: "#00934F",
                            fontSize: { xs: "14px", sm: "16px" },
                            transition: "color 0.2s ease-in-out",
                          }}
                          className="hover-icon"
                        />
                        <Typography
                          sx={{
                            fontSize: { xs: "12px", sm: "14px" },
                            color: "gray",
                            textAlign: "left",
                            transition: "color 0.2s ease-in-out",
                            flex: 1,
                          }}
                          className="hover-text"
                        >
                          {feature}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      mt: "auto",
                    }}
                  >
                    {/* <Button
                sx={{
                    color: '#00934F',
                    backgroundColor: '#EDECF9',
                    textTransform: 'capitalize',
                    borderRadius: '50px',
                    width: '100%',
                    zIndex: '0',
                    maxWidth: '150px',
                    fontSize: { xs: '10px', sm: '14px' },
                    mt: 2,
                    '&:hover': {
                        backgroundColor: '#EDECF9',
                    },
                }}
                onClick={handleBookDemo}
            >
                Choose Plan
            </Button> */}
                  </Box>
                </Box>
                {/* Price in India text with flag */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    mb: 2,
                  }}
                >
                  <img
                    src="https://www.bing.com/th?id=OSB.dApx4wDP49_XpM5W7yCJng--.png&pid=MSports&w=180&h=180&qlt=90&c=0&rs=1&dpr=2&p=1"
                    width="20px"
                    height="20px"
                  />
                  <Typography
                    sx={{
                      fontSize: { xs: "12px", sm: "15px" },
                      color: "gray",
                      marginLeft: "10px",
                    }}
                    className="hover-text"
                  >
                    Price in India
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
        <Box sx={{ mt: 6 }}>
          <Footer demoOpen={demoOpen} setDemoOpen={setDemoOpen} />
        </Box>
      </Box>

      <Box
        sx={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          zIndex: 1000,
          cursor: "pointer",
        }}
      >
        <a
          href="https://api.whatsapp.com/send/?phone=917396986638&text=Hi%0AEngage%20to"
          target="_blank"
        >
          <img
            src="/whatsupicon.png"
            alt="WhatsApp"
            style={{
              width: "60px",
              height: "60px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.9)",
              borderRadius: "50%",
              transition: "box-shadow 0.3s",
            }}
          />
        </a>
      </Box>
    </div>
  );
};

export default WebsitePage;
