import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  useMediaQuery,
  Button,
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-scroll";
import { useNavigate } from "react-router-dom";

const Navbar = ({ demoOpen, setDemoOpen }: any) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [clickedItem, setClickedItem] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const handleClick = (item: any) => {
    setClickedItem(item);
    setAnchorEl(null);
  };

  const handleDemoClick = () => {
    setDemoOpen(true);
  };

  const handleLoginClick = () => {
    navigate("/login");
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleStartFreeTrail = () => {
    navigate("/websitePaymentDetails");
  };

  const items = [
    { name: "Home", to: "home" },
    { name: "Features", to: "features" },
    { name: "Pricing", to: "pricing" },
    { name: "About Us", to: "aboutus" },
    { name: "Contact Us", to: "contactus" },
  ];

  return (
    <Box
      position="static"
      sx={{
        backgroundColor: "white",
        // zIndex: "9999",
        position: "fixed",
        width: "100%",
        top: 0,
        zIndex: 10,
        boxShadow:
          "0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)",
        // borderBottom: '1px solid black'
        // width: "full",
        paddingLeft: "10px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {/* {isMobile && (
            <IconButton
              edge="start"
              // color="inherit"
              aria-label="menu"
              onClick={handleMenuClick}
            >
              <MenuIcon />
            </IconButton>
          )} */}
          <img
            src="/engagetoLogo.png"
            alt="engageto Logo"
            style={{ height: "60px" }}
            onClick={isMobile ? handleMenuClick : undefined}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexGrow: 2,
            justifyContent: "center",
            gap: "30px",
            alignItems: "center",
          }}
        >
          {!isMobile && (
            <>
              {items.map((item) => (
                <Link
                  key={item.to}
                  to={item.to}
                  smooth={true}
                  duration={500}
                  onClick={() => handleClick(item.name)}
                  style={{ textDecoration: "none", cursor: "pointer" }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      margin: "0 25px",
                      color: clickedItem === item.name ? "green" : "black",
                      fontSize: "20px",
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                  >
                    {item.name}
                  </Typography>
                </Link>
              ))}
            </>
          )}
        </Box>
        {/* Buttons */}
        <Box sx={{ paddingRight: "15px" }}>
          <a
            href="https://www.app.engageto.com/#/login"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: "inline-block",
              textDecoration: "none",
              color: "green",
            }}
          >
            <Button
              sx={{
                border: "1.5px solid #00934F",
                textTransform: "capitalize",
                borderRadius: "50px",
                backgroundColor: "#FFFFFF",
                color: "#00934F",
                fontSize: "16px",
                width: "100px",
              }}
              // onClick={handleLoginClick}
            >
              Log in
            </Button>
          </a>
          <Button
            sx={{
              marginLeft: "15px",
              backgroundColor: "#00934F",
              color: "#FFFFFF",
              textTransform: "capitalize",
              borderRadius: "50px",
              fontSize: "16px",
              width: "150px",
              "&:hover": {
                backgroundColor: "#00934F",
              },
            }}
            // onClick={handleStartFreeTrail}
            onClick={handleDemoClick}
          >
            Book a demo
          </Button>
        </Box>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        // keepMounted
      >
        {items.map((item) => (
          <MenuItem
            key={item.to}
            onClick={() => handleClick(item.name)}
            sx={{ padding: "0px 0", margin: "0" }}
          >
            <Link
              to={item.to}
              smooth={true}
              duration={500}
              style={{
                textDecoration: "none",
                cursor: "pointer",
                color: clickedItem === item.name ? "green" : "black",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  margin: "0 25px",
                  color: clickedItem === item.name ? "green" : "black",
                  fontSize: "16px",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                {item.name}
              </Typography>
            </Link>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default Navbar;
